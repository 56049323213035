<template>
  <div>
    <b-modal
      id="modal-edit-color"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('EditColor')} ${dressName}`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.name.$model"
            type="text"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <AddFile
          v-if="image.imgs.length <= 2"
          ref="addFile"
          :folder="'ProductPhotos'"
          :label-text="'UploadPhoto'"
          :file-name="form.fileName"
        />
        <div>
          <p
            v-if="isTextShown && image.imgs.length != 0"
            class="text"
            style="font-size:small; color:#242F6E"
          >
            {{ $t('ClickToViewPhoto') }}
          </p>
          <div
            style="display: flex; flex-direction: row !important;"
          >
            <div
              v-for="(img, index) in image.imgs"
              :key="img.fileDataId"
              :class="{'second-photo': index === 1, 'third-photo': index === 2,}"
              style="background-color: gainsboro; border-radius: 8px; height: 56px; display: flex; justify-content: center; align-items: center;"
            >
              <img
                :class="{'photo': true, 'hovered': hoveredIndex === index,'second-photo': index === 1, 'third-photo': index === 2,}"
                :style="{ transform: `scale(${img.zoom})` }"
                :src="img.src"
                @click="zoomIn(index)"
                @mouseover="showText()"
                @mouseleave="zoomOut"
              >
              <b-icon-x-circle
                :key="img.fileDataId"
                style="margin-left: 25px; margin-right: 5px;"
                @click="handleDeleteClick(img.fileDataId)"
              />
            </div>
          </div>
        </div>

        <b-form-group />
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            :disabled="loading"
            @click="onSubmit"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
import AddFile from '../../../modals/AddFile.vue'


export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['dressName', 'color', 'image'],
  data() {
    return {
      loading: false,
      deleteButton: false,
      photoId: null,
      isTextShown: false,
      hoveredIndex: null,
      text: 'Click to zoom the photo',
      form: {
        mainDressCategoryColorId: '',
        name: '',
        fileName: [],
      },
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    // currentImages() {
    //   return this.image.imgs.filter((img, index) => { return index === this.image.index })
    // },
  },
  mounted() {
    this.form = this.color
    this.form.fileName = []
  },
  methods: {
    ...mapActions([
      'deleteImage',
      'loadMaterialVariant',
    ]),
    handleDeleteClick(fileDataId) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this photo?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.deletePhoto(fileDataId);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async deletePhoto(value) {
      await this.deleteImage({
        id: value,
        successCallback: () => {
          this.$emit('getPhotos', this.form)
        },
      })
    },
    zoomIn(index) {
      this.hoveredIndex = index;
    },
    zoomOut() {
      this.hoveredIndex = null;
    },
    showText() {
      this.isTextShown = true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.loading = true;
        await this.$refs.addFile.uploadFile();
        this.$emit('updateColor', this.form)
        this.$refs.modal.hide()
        this.resetForm()
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      this.loading = false;
      this.fileName = [];
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      // this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.colorName = ''
      this.form.fileName = []
      this.form.mainDressCategoryColorId = ''
      this.isTextShown = false
      this.hoveredIndex = null
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .photo{
    margin-left: 5px;
    height: 50px;
    border-radius: 8px;
  }

  .hovered {
  /* Additional styles for the hovered photo */
  transform: scale(7.2);
  border-radius: 0px;
}
.second-photo{
  margin-left: 20px;
}
.third-photo{
  margin-left: 20px;
}

</style>
