<template>
  <div>
    <b-modal
      id="modal-add-color"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('SubmitColor')} ${dressName}`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.colorName.$model"
            :state="validateState('colorName')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <pre />
        <AddFile
          ref="addFile"
          :folder="'ProductPhotos'"
          :label-text="'UploadPhoto'"
          :file-name="form.fileName"
        />
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            :disabled="loading"
            class="buttonSubmit"
            @click="onSubmit"
          >
            <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
            />
            {{ $t('Submit') }}

          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import AddFile from '../../../modals/AddFile.vue'


export default {
  components: {
    AddFile,
  },
  mixins: [validationMixin],

  // eslint-disable-next-line vue/require-prop-types
  props: ['dressName'],
  data() {
    return {
      loading: false,
      form: {
        mainDressCategoryId: '',
        colorName: '',
        fileName: [],
      },
    }
  },
  validations: {
    form: {
      colorName: {
        required,
        // minLength: minLength(3),
        // alpha,
      },
    },
  },
  computed: {
    ...mapGetters([
    ]),
  },
  methods: {
    ...mapActions([
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.loading = true;
        await this.$refs.addFile.uploadFile();
        await this.$emit('materials', this.form)
        this.$refs.modal.hide()
        this.resetForm()
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
          .then((res) => {
            console.log(res);
          })
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
      this.fileName = [];
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.colorName = ''
      this.form.fileName = []
      this.fileData = []
    },
  },
}
</script>

<style lang="scss" scoped>

.flex-form {
  display: flex;
  flex-direction: column;
}
</style>
