<template>
  <div>
    <b-modal
      id="modal-edit-size"
      ref="modal"
      header-class="headerModal"
      centered
      size="lg"
      :title="`${$t('EditSize')} ${materials.name} ${$t('EditSizeFor')} ${colorData.colorName}`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <div style="display: flex; flex-direction: row;">

          <b-form-group
            id="input-select-1"
            :label="`${$t('Selectdresssize')}:`"
            label-for="select-1"
            style="width: 48%;"
          >
            <vue-select
              v-model="masa.size"
              :options="sizeOptions"
              :reduce="(e) => e.size"
              required
              :placeholder="$t('SelectSize')"
              label="size"
            />
          </b-form-group>
          <b-form-group
            id="input-select-1"
            :label="`${$t('Price')}:`"
            label-for="select-1"
            style="margin-left: 25px; width: 18%;"
          >
            <b-form-input
              v-model.number="prices.price"
              required
              :placeholder="$t('Price')"
              type="number"
              label="size"
            />
          </b-form-group>
          <b-form-group
            v-if="getLoggedInUser.role == 'CEO' || getLoggedInUser.role == 'Admin'"
            id="input-select-1"
            :label="`${$t('PurchasePrice')}:`"
            label-for="select-1"
            style="margin-left: 2px; width: 18%;"
          >
            <b-form-input
              v-model.number="prices.purchasePrice"
              required
              :placeholder="$t('Price')"
              type="number"
              label="size"
            />
          </b-form-group>
          <b-form-group
            id="input-select-1"
            :label="`${$t('Currency')}:`"
            label-for="select-1"
            style="margin-left: 2px; width: 12%;"
          >
            <vue-select
              v-model="prices.currency"
              :options="currencies"
              required
              label="currency"
            />
          </b-form-group>
        </div>
        <div
          class="modal-card__wrapper"
          style="align-items: center"
        >
          <div
            v-for="(formItem, k) in form"
            :key="k"
            class="modal-card"
          >
            <b-form-group
              id="input-group-3"
              :label="`${$t('SelectMaterialType')}:`"
            >
              <i
                class="modal-card__remove"
                @click="removeMaterialItem(formItem.materialVariantId, formItem.id)"
              ><b-icon-trash /></i>
              <div class="flex radio-form">
                <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                  <div
                    class="flex aic"
                    @click="resetMeter(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Meter"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Meter') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetPackage(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Package"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Package') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetSet(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Set"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Set') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetPair(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Pair"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('Pair') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetVerse(formItem)"
                  >
                    <input
                      :id="`one${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Verse"
                      required
                    >
                    <label :for="`one${k}`">{{ $t('BeadedStrand') }}</label>
                  </div>
                  <div
                    class="flex aic"
                    @click="resetPart(formItem)"
                  >
                    <input
                      :id="`two${k}`"
                      v-model="formItem.measurementType"
                      type="radio"
                      value="Parts"
                      required
                    >
                    <label :for="`two${k}`">{{ $t('Part') }}</label>
                  </div>
                </div>
              </div>
            </b-form-group>

            <div class="search-toggle">

              <p>{{ $t('SearchBy') }}</p>
              <div>
                <p
                  :class="{ active2: toggleSearch === 'MaterialName' }"
                  @click="showMaterialName(), toggleSearch = 'MaterialName'"
                >
                  {{ $t('Name') }}
                </p>
                <p
                  :class="{ active2: toggleSearch === 'Description' }"
                  @click="showDescription(), toggleSearch = 'Description'"
                >
                  {{ $t('Description') }}
                </p>
              </div>
            </div>

            <b-form-group
              id="input-select-1"
              label-for="select-1"
            >
              <vue-select
                v-if="formItem.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
                v-model="formItem.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
                :reduce="(e) => e"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                @search="onSearchM"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Meter' && toggleSearch === 'Description'"
                v-model="formItem.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
                :reduce="(e) => e"
                :placeholder="$t('Description')"
                label="materialVariantName"
                @search="onSearchMDesc"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageM == 1"
                    @click="prevMeter"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                    @click="nextMeter"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Package' && toggleSearch === 'MaterialName'"
                v-model="formItem.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
                :reduce="(e) => e"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                @search="onSearchPack"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pagePackage == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pagePackage == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePackage == getTotalItemsPages : pagePackage == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>

              <vue-select
                v-else-if="formItem.measurementType === 'Package' && toggleSearch === 'Description'"
                v-model="formItem.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
                :reduce="(e) => e"
                :placeholder="$t('Description')"
                label="materialVariantName"
                @search="onSearchPackDesc"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pagePackage == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pagePackage == 1"
                    @click="prevPackage"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPackage != '' ? pagePackage == getTotalItemsPages : pagePackage == getTotalPagesForMVM "
                    @click="nextPackage"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>

              <vue-select
                v-else-if="formItem.measurementType === 'Set' && toggleSearch === 'MaterialName'"
                v-model="formItem.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
                :reduce="(e) => e"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                @search="onSearchSet"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageSet == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Set' && toggleSearch === 'Description'"
                v-model="formItem.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
                :reduce="(e) => e"
                :placeholder="$t('Description')"
                label="materialVariantName"
                @search="onSearchSetDesc"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageSet == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageSet == 1"
                    @click="prevSet"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                    @click="nextSet"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
                v-model="formItem.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
                :reduce="(e) => e"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                @search="onSearchPair"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pagePair == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Pair' && toggleSearch === 'Description'"
                v-model="formItem.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
                :reduce="(e) => e"
                :placeholder="$t('Description')"
                label="materialVariantName"
                @search="onSearchPairDesc"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pagePair == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pagePair == 1"
                    @click="prevPair"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                    @click="nextPair"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
                v-model="formItem.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
                :reduce="(e) => e"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                @search="onSearchVerse"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageVerse == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>

              <vue-select
                v-else-if="formItem.measurementType === 'Verse' && toggleSearch === 'Description'"
                v-model="formItem.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
                :reduce="(e) => e"
                :placeholder="$t('Description')"
                label="materialVariantName"
                @search="onSearchVerseDesc"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageVerse == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageVerse == 1"
                    @click="prevVerse"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    :style="getButtonStyle"
                    type="button"
                    :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                    @click="nextVerse"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>

              <vue-select
                v-else-if="formItem.measurementType === 'Parts' && toggleSearch === 'MaterialName'"
                v-model="formItem.name"
                :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
                :reduce="(e) => e"
                :placeholder="$t('SelectMaterialVariant')"
                label="materialVariantName"
                style="-ms-word-break: break-all; word-break: break-all; word-break: break-word;"
                @search="onSearchP"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ?pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
              <vue-select
                v-else-if="formItem.measurementType === 'Parts' && toggleSearch === 'Description'"
                v-model="formItem.name"
                :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
                :reduce="(e) => e"
                :placeholder="$t('Description')"
                label="materialVariantName"
                style="-ms-word-break: break-all; word-break: break-all; word-break: break-word;"
                @search="onSearchPDesc"
              >
                <li
                  slot="list-footer"
                  class="pagination"
                  style="display: flex; width: 100%; justify-content: center"
                >
                  <button
                    type="button"
                    :style="pageM == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
                    :disabled="pageP == 1"
                    @click="prevPart"
                  >
                    {{ $t('Prev') }}
                  </button>
                  <button
                    type="button"
                    :style="getButtonStylePart"
                    :disabled="selectedMaterialVariantPart != '' ?pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                    @click="nextPart"
                  >
                    {{ $t('Next') }}
                  </button>
                </li>
              </vue-select>
            </b-form-group>
            <b-form-group
              v-if="formItem.measurementType === 'Meter'"
              :label="`${$t('Meter')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
              />
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Package'"
              :label="`${$t('Package')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
              />
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Set'"
              :label="`${$t('Set')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
              />
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Pair'"
              :label="`${$t('Pair')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
              />
            </b-form-group>
            <b-form-group
              v-else-if="formItem.measurementType === 'Verse'"
              :label="`${$t('Verse')}:`"
            >
              <b-input
                v-model="formItem.length"
                type="number"
              />
            </b-form-group>
            <b-form-group
              v-else
              :label="`${$t('Part')}:`"
            >
              <b-input
                v-model="formItem.quantity"
                type="number"
              />
            </b-form-group>
          </div>
          <button
            type="button"
            size="sm"
            class="button"
            style="margin: 20px 0; border-radius: 6px;"
            @click="addNewRow"
          >
            <p style="margin: 0">
              + {{ $t('AddMaterial') }}
            </p>
          </button>
        </div>
        <b-form-group />
        <p
          v-if="errors.duplicateError"
          style="color: red;"
        >
          {{ $t('DuplicateMaterial') }}
        </p>
        <p
          v-if="errors.emptyMaterial"
          style="color: red;"
        >
          {{ $t('EmptyMaterial') }}
        </p>
        <b-form-group />
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['colorData', 'materials', 'materialEditItem', 'measureItem', 'eSize', 'pricess', 'materialsArticleNum',
  ],
  data() {
    return {
      toggleSearch: 'MaterialName',
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      partOptions: [],
      meterOptions: [],
      errors: {
        duplicateError: false,
        emptyMaterial: false,
      },
      totalItems: '',
      sizeOptions: [
        {
          size: 'xs',
        },
        {
          size: 's',
        },
        {
          size: 'm',
        },
        {
          size: 'l',
        },
        {
          size: 'xl',
        },
      ],
      options: [],
      selected: null,
      isUndefined: false,
      masa: {
        size: {
          type: String,
        },
      },
      prices: {
        price: 0,
        purchasePrice: 0,
        currency: '',
      },
      currencies: ['€', '$', 'CHF'],
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      pageSize: 15,
      form: [
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentPageForMVM',
      'getTotalPagesForMVM',
      'getCurrentPageForMVP',
      'getTotalPagesForMVP',
      'getFilteredMV',
      'getMaterialsVByMeter',
      'getMaterialsVByPart',
      'getMaterialVariantColorNameLike',
      'getTotalItemsPages',
      'getMaterialsVByPackage',
      'getMaterialsVBySet',
      'getMaterialsVByVerse',
      'getMaterialsVByPair',
      'getSavedStock',
      'getFilterVariantsByCodeLike',
      'getLoggedInUser',
    ]),
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
  },
  watch: {
    form: {
      handler(newValue) {
        const materialVariantIds = new Set();
        let duplicateError = false;
        newValue.forEach((item, index) => {
          if (!item.name) {
            // Check for empty materialVariantId
            this.errors.emptyMaterial = true;
          } else {
            this.errors.emptyMaterial = false;
          }
          if (item.materialVariantId) {
            if (materialVariantIds.has(item.materialVariantId)) {
              duplicateError = true;
              return;
            }
            materialVariantIds.add(item.materialVariantId);
          }
          if (item.name && item.name.materialVariantId) {
            if (materialVariantIds.has(item.name.materialVariantId)) {
              if (item.materialVariantId === item.name.materialVariantId) {
                duplicateError = false;
              } else {
                duplicateError = true;
              }
            } else {
              const isDuplicate = newValue.slice(index + 1).some((otherItem) => { return otherItem.materialVariantId === item.name.materialVariantId });
              if (isDuplicate) {
                duplicateError = true;
                return;
              }
              materialVariantIds.add(item.name.materialVariantId);
            }
          }
        });

        this.errors.duplicateError = duplicateError;
      },
      deep: true,
    },
    measureItem() {
      this.form.measurementType = this.measureItem;
    },
    materialsArticleNum() {
      this.fillForm()
    },
    // eslint-disable-next-line func-names
    'pricess.price': function (value) {
      this.prices.price = value;
    },
    // eslint-disable-next-line func-names
    'pricess.currency': function (value) {
      this.prices.currency = value;
    },
    // eslint-disable-next-line func-names
    'pricess.purchasePrice': function (value) {
      this.prices.purchasePrice = value;
    },
    // eslint-disable-next-line func-names
    'prices.price': function (value) {
      if (value == '') {
        this.prices.price = 0;
      }
    },
    // eslint-disable-next-line func-names
    'prices.purchasePrice': function (value) {
      if (value == '') {
        this.prices.purchasePrice = 0;
      }
    },
  },
  mounted() {
    this.masa = this.eSize;
  },
  methods: {
    ...mapActions(['resetAllFiltered', 'filterVariantByColor',
      'getMaterialVariantsToMDCPagination', 'loadMaterialVaraintsCategoryNameLike',
      'testResetMV', 'filterVariantByCode']),
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    addNewRow() {
      this.form.push({
        id: `s${Math.floor(Math.random() * 12000)}`,
        materialVariantId: '',
        name: '',
        measurementType: 'Meter',
        length: 0,
        quantity: 0,
      });
    },

    removeMaterialItem(id, id2) {
      this.form = this.form.filter((item) => {
        return item.materialVariantId !== id || item.id !== id2;
      });
    },
    resetMeter(value) {
      value.materialVariantId = '';
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPart(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      value.name = '';
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    resetPackage(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      value.name = '';
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      value.name = '';
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      value.name = '';
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.materialVariantId = '';
      value.quantity = 0
      value.length = 0
      value.name = '';
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },

    async getMVbyMeter(page, pageS) {
      // const { data } =
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      // const { data } =
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    fillForm() {
      this.form = []
      for (let i = 0; i < this.materialsArticleNum.length; i++) {
        this.form.push(this.materialsArticleNum[i])
      }
    },

    onSubmit() {
      const materialVariants = []

      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.form) {
        if (typeof item.name === 'object') {
          materialVariants.push({
            name: item.name.materialVariantName, measurementType: item.measurementType, materialVariantId: item.name.materialVariantId, length: item.length, quantity: item.quantity,
          })
        } else {
          materialVariants.push({
            name: item.name, measurementType: item.measurementType, materialVariantId: item.materialVariantId, length: item.length, quantity: item.quantity,
          })
        }
      }

      const object = {
        mainDressCategoryColorId: this.colorData.mainDressCategoryColorId,
        size: this.masa.size,
        price: this.prices.price,
        purchasePrice: this.prices.purchasePrice,
        currency: this.prices.currency,
        materialVariantDtos: materialVariants,
      };

      if (this.errors.duplicateError || this.errors.emptyMaterial) {
        return;
      }

      this.$emit('updateSize', object);
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
        this.form = [];
      }, 10);
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      setTimeout(() => {
        this.resetForm();
        this.form = []
      }, 10);
    },
    resetForm() {
      // Reset our form values
      this.errors.duplicateError = ''
      this.form = [
        {
          materialVariantId: '',
          measurementType: 'Meter',
          name: '',
          length: '',
          quantity: '',
        },
      ];
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $base-color;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.pagination {
  display: flex !important;
  width: 100%;
  margin: 0.25rem 0.25rem 0 !important;
}
</style>
